<template>
  <div class="filter">
    <div class="filter__row">
      <slot></slot>
    </div>
    <div class="filter__action">
      <ui-button :disabled="disabled" @click="submit">{{ $t('apply') }}</ui-button>
      <ui-button @click="reset" color="outline">{{ $t('reset') }}</ui-button>
    </div>
  </div>
</template>

<script>

export default {
  name: "SearchFilter",
  components: {
    UiButton: () => import("@/components/ui/UiButton"),
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  methods: {
    reset() {
      this.$emit('reset')
    },

    submit() {
      if(this.disabled) return

      this.$emit('submit')
    },
  }
}
</script>

<style lang="scss" scoped>
.filter {
  padding: 30px 56px;
  background: #FFFFFF;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    padding: 30px 12px;
  }
  &__row {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-wrap {
      display: flex;
      gap: 20px;

      @media (max-width: 992px) {
        flex-direction: column;
      }
    }
  }

  &__action {
    max-width: 424px;
    display: grid;
    gap: 24px;
    grid-template-columns: 320px 1fr;
    margin-top: 10px;

    @media (max-width: 992px) {
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-width: 100%;
    }
  }
}
</style>
